import React from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

const CustomModal = ({
  children,
  isOpen,
  setIsOpen,
  modalTitle,
  title,
  subTitle,
  applyButton,
  responseMessage,
  icon,
}) => {
  const navigate = useNavigate();
  const customStyles = {
    overlay: {
      zIndex: 999,
      backgroundColor: "rgba(15, 15, 15, 0.5)",
      backdropFilter: "blur(4px)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 99999,
      borderRadius: "12px",
      width: "400px",
      backgroundColor: "#FFF",
      borderColor: "#FFF",
      position: "fixed",
    },
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        overlayClassName="fixed inset-0 flex items-center justify-center bg-[#0F0F0F] bg-opacity-50 backdrop-blur-[4px] z-[999]"
      >
        <div className="w-full h-[200px] xs:h-[308px] items-center justify-center flex flex-col ">
          <div className="flex  flex-col items-center gap-2 xs:gap-4 w-full justify-center h-[72px]  ">
            <div
              className={`flex w-16 h-16 p-3  justify-center items-center rounded bg-[#FEE4E2]`}
            >
              {icon ? (
                <div
                  className={`flex w-20 h-20 p-3 justify-center items-center rounded bg-[#FEE4E2]`}
                >
                  {icon}
                </div>
              ) : (
                <div
                  className={`flex w-12 h-12 p-3 justify-center items-center rounded bg-[#FEE4E2]`}
                >
                  deneme
                  {/* <Delete /> */}
                </div>
              )}
            </div>

            <span className="w-full h-7 flex items-center justify-center font-semibold text-lg text-[#101828] dark:text-[#FAFAFA] mb-1">
              {title}
            </span>
            <span className="w-full h-14 font-normal items-center text-center justify-center text-sm text-[#667085] dark:text-[#D6D6D6]">
              {subTitle}
            </span>
            <div className="flex flex-row w-full h-10 items-end gap-x-3 mt-4 ">
              {responseMessage?.data?.code == 1 ? (
                <button
                  onClick={() => {
                    window.open(
                      "https://cdn.upu.io/upuio-pitch-deck",
                      "_blank"
                    );
                    setIsOpen(false);
                  }}
                  style={{
                    height: "44px",
                  }}
                  className="flex w-full h-11  items-center justify-center border border-secondary-300 rounded-lg bg-white text-[#344054] font-medium text-lg"
                >
                  {applyButton}
                </button>
              ) : (
                <button
                  onClick={() => {
                    window.open(
                      "https://cdn.upu.io/upuio-pitch-deck",
                      "_blank"
                    );
                    setIsOpen(false);
                    window?.location?.reload(navigate("/investor"));
                  }}
                  style={{
                    height: "44px",
                  }}
                  className="flex w-full h-11  items-center justify-center border border-secondary-300 rounded-lg bg-white text-[#344054] font-medium text-lg"
                >
                  {applyButton}
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CustomModal;
