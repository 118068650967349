import React, { useState } from "react";
import CustomModal from "./CustomModal";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  ApplyIcon,
  ErrorIcon,
  InvestorBackground3,
  PurpleNewLogo,
} from "../assets";
import { Loader } from "./loading";
import axios from "axios";

const ForInvester = () => {
  const [email, setEmail] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [error, setError] = useState("");

  const { width } = useWindowDimensions();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [responseMessage, setResponseMessage] = useState("");
  const sendMail = async (e) => {
    setLoading(true);
    if (error === "") {
      const response = await axios.post(
        "https://api.upu.io/v1/account/send/investor/mail",
        {
          email: email,
        }
      );
      setResponseMessage(response);
      if (response?.data?.code == 1) {
        setModalIsOpen(true);
      } else {
        setModalIsOpen(true);
        setEmail("");
      }
    }
    setLoading(false);
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      setError(""); // Hata mesajını temizle
    } else {
      setError("Please enter a valid email address.");
    }
  };

  return (
    <>
      <form
        onSubmit={sendMail}
        style={{
          display: width < 1000 ? "none" : "flex",
        }}
        className="flex items-center  overflow-hidden justify-center h-screen relative"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <div
            style={{
              display: "flex",

              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <PurpleNewLogo />
            </div>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "70%",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "30px",
                  marginTop: 12,
                  color: "#101828",
                }}
              >
                2024 Series A Investment Presentation
              </p>
            </div>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  color: "#475467",
                  fontWeight: "400",
                }}
              >
                Please enter your email to view the file.
              </p>
            </div>
          </div>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 12,
              width: "65%",
            }}
          >
            <label
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#344054",
                width: "100%",
              }}
            >
              Email
              <input
                onChange={(e) => setEmail(e.target.value)}
                onBlur={validateEmail}
                style={{
                  height: 44,
                  borderRadius: 8,
                  width: "100%",
                  paddingLeft: 12,
                  borderColor: "#D0D5DD",
                  borderWidth: 1,
                  outline: "none",
                }}
                placeholder="Enter Your Email"
              />
              <p style={{ color: "red" }}>{error}</p>
            </label>
          </div>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "65%",
            }}
          >
            {/* <ReCAPTCHA
          sitekey={"6LdxeUopAAAAANDqwerwvfv6tD9YH9usM5gEKEyX"}
          onChange={(e) => setCaptcha(e)}
        /> */}
            {loading ? (
              <div className="flex items-center justify-center w-full h-full">
                <Loader currentColor="#7F56D9" currentFill="currentFill" />
              </div>
            ) : (
              <button
                onClick={() => {
                  sendMail();
                }}
                type="button"
                disabled={captcha === "" && error != "" ? true : false}
                style={{
                  backgroundColor: "#7F56D9",
                  width: "100%",
                  height: "44px",
                  borderRadius: "8px",
                  marginTop: 24,
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "500",
                  opacity: captcha === "" && error != "" ? 0.5 : 1,
                }}
              >
                Send
              </button>
            )}
          </div>
        </div>
        <div
          style={{
            width: "50%",
          }}
        >
          <InvestorBackground3 />
        </div>
        <p
          style={{
            position: "absolute",
            left: 16,
            bottom: 12,
            fontWeight: "400",
            fontSize: "14px",
          }}
        >
          © upu.io 2024
        </p>
      </form>

      {/* -------------------- Mobile View  ------------------------ */}
      <>
        <form
          style={{
            paddingTop: "62px",
            display: width > 1000 ? "none" : "flex",
          }}
          className="flex items-start  overflow-hidden  h-screen relative"
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                paddingLeft: "24px",
                paddingRight: "24px",
                paddingTop: "62px",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <PurpleNewLogo />
              </div>
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "28px",
                    marginTop: 12,
                    color: "#101828",
                  }}
                >
                  2024 Seria A Investment Presentation
                </p>
              </div>
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    color: "#475467",
                    fontWeight: "400",
                  }}
                >
                  Please enter your email to view the file.
                </p>
              </div>
            </div>
            <div
              style={{
                paddingLeft: "24px",
                paddingRight: "24px",
                width: "100%",
              }}
            >
              <label
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#344054",
                  width: "100%",
                }}
              >
                Email
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={validateEmail}
                  style={{
                    height: 44,
                    borderRadius: 8,
                    paddingLeft: 12,
                    borderColor: "#D0D5DD",
                    borderWidth: 1,
                    width: "100%",
                  }}
                  placeholder="Enter Your Email"
                />
                <p style={{ color: "red" }}>{error}</p>
              </label>
            </div>

            <div
              style={{
                paddingLeft: "24px",
                paddingRight: "24px",
                width: "100%",
              }}
            >
              {/* <ReCAPTCHA
            sitekey={"6LdxeUopAAAAANDqwerwvfv6tD9YH9usM5gEKEyX"}
            onChange={(e) => setCaptcha(e)}
          /> */}
              <button
                onClick={() => {
                  sendMail();
                }}
                type="button"
                disabled={captcha === "" && error != "" ? true : false}
                style={{
                  backgroundColor: "#7F56D9",
                  width: "100%",
                  height: "44px",
                  borderRadius: "8px",
                  marginTop: 12,
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "500",
                  opacity: captcha === "" && error != "" ? 0.5 : 1,
                }}
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </>
      <CustomModal
        isOpen={modalIsOpen}
        responseMessage={responseMessage}
        setIsOpen={setModalIsOpen}
        setEmail={setEmail}
        title={
          responseMessage?.data?.code == 1
            ? "Submission Failed"
            : "Sending Successful"
        }
        subTitle={responseMessage?.data?.message}
        applyButton={
          responseMessage?.data?.code == 1 ? "View Pdf File" : "View Pdf File"
        }
        icon={responseMessage?.data?.code == 1 ? <ErrorIcon /> : <ApplyIcon />}
      />
    </>
  );
};

export default ForInvester;
