import { BrowserRouter, Routes, Route } from "react-router-dom";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { Suspense, lazy, useEffect, useState } from "react";
import { Loader } from "./components/loading.js";
// import Navbar from "./components/Navbar";
import ForInvester from "./components/ForInvester";
import Skeleton from "react-loading-skeleton";
// import AccessAnyDevices from "./pages/AccessAnyDevices";
// import AutomationPyramid from "./pages/AutomationPyramid";
// import WhyUpu from "./pages/WhyUpu";
// import WhyUpuSecondPage from "./pages/WhyUpuSecondPage";
// import WhyUpuThirdPage from "./pages/WhyUpuThirdPage";
// import SustaniableManufacturing from "./pages/SustaniableManufacturing";
// import ProductSection from "./pages/ProductSection";
// import SolutionSection from "./pages/SolutionSection";
// import MachinePackage from "./pages/MachinePackage";
// import PersonPackage from "./pages/PersonPackage";
// import ProductPackage from "./pages/ProductPackage";
// import StoryOfUpu from "./pages/StoryOfUpu";
// import ScrollTopButton from "./pages/ScrollTopButton";

const delay = (promise) => {
  return new Promise((resolve) => {
    setTimeout(resolve, 1000);
  }).then(() => promise);
};

const Navbar = lazy(() => import("./components/Navbar.js"));
const AccessAnyDevices = lazy(() =>
  delay(import("./pages/AccessAnyDevices.js"))
);
const AutomationPyramid = lazy(() => import("./pages/AutomationPyramid.js"));
const WhyUpu = lazy(() => import("./pages/WhyUpu.js"));
const WhyUpuSecondPage = lazy(() => import("./pages/WhyUpuSecondPage.js"));
const WhyUpuThirdPage = lazy(() => import("./pages/WhyUpuThirdPage.js"));
const SustaniableManufacturing = lazy(() =>
  import("./pages/SustaniableManufacturing.js")
);
const ProductSection = lazy(() => import("./pages/ProductSection.js"));
const SolutionSection = lazy(() => import("./pages/SolutionSection.js"));
const MachinePackage = lazy(() => import("./pages/MachinePackage.js"));
const PersonPackage = lazy(() => import("./pages/PersonPackage.js"));
const ProductPackage = lazy(() => import("./pages/ProductPackage.js"));
const StoryOfUpu = lazy(() => import("./pages/StoryOfUpu.js"));

function App() {
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("index.html")) {
      window.location.replace("https://www.upu.io");
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <main style={{ width: width }} className="App overflow-hidden">
              <Suspense>
                <Navbar />
              </Suspense>
              <Suspense fallback={<div className="preloader"></div>}>
                <AccessAnyDevices />
              </Suspense>
              <Suspense>
                <AutomationPyramid />
              </Suspense>
              <Suspense>
                <WhyUpu />
              </Suspense>
              <Suspense>
                <WhyUpuSecondPage />
              </Suspense>
              <Suspense>
                <WhyUpuThirdPage />
              </Suspense>
              <Suspense>
                <SustaniableManufacturing />
              </Suspense>
              <Suspense>
                <ProductSection />
              </Suspense>
              <Suspense>
                <SolutionSection />
              </Suspense>
              <Suspense>
                <MachinePackage />
              </Suspense>
              <Suspense>
                <PersonPackage />
              </Suspense>
              <Suspense>
                <ProductPackage />
              </Suspense>
              <Suspense>
                <StoryOfUpu />
              </Suspense>

              {/* <ScrollTopButton /> */}
            </main>
          }
        />
        <Route path="/investor" element={<ForInvester />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
